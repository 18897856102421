
//@ts-ignore
import { Type } from "@/interfaces/custom_list";
import Vue from "vue";
//@ts-ignore
import { isRequired, isNumber, isNotZero, isMaxCustomLength } from "@/services/rule-services";
//@ts-ignore
import CustomListItems from "@/views/Admin/CustomListItems/index.vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
//@ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { mapActions } from "vuex";
// @ts-ignore
import { isUndefined } from 'lodash';

export default Vue.extend({
	name: "CustomListForm",
	props: {
		custom_list: {
			type: Object,
			default: function () {
				return {};
			},
		},
		is_edit: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		CustomListItems,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		CardActions,
	},
	data: () => ({
		is_radius_enabled: false,
		resources:{
			types: []
		}
	}),
	created() {
		this.$nextTick(async function () {
			await this.setLoadingData(TypeLoading.loading);
			/*
			if (this.isEdit) {
				this.is_radius_enabled = this.custom_list.type?.key == "lat_long";
			}*/
			await this.loadTypes();
			await this.setLoadingData();
		});
	},
	mounted() {
		this.$nextTick(async function () {
			await this.setLoadingData(TypeLoading.loading);
			await this.loadTypes();
			await this.loadData();
			await this.setLoadingData();
		});
	},
	computed: {
		isEdit() {
			return this.is_edit;
		},
		getTypes(): Type[] {
			return this.$store.state.custom_list.types;
		},
		getRules() {
			return { isRequired, isNumber, isNotZero, isMaxCustomLength};
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},

		isEnabledRadius() {
			return this.CheckTypes(this.custom_list.custom_list_type_id);
			//return this.custom_list.type?.key == "lat_long";
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		async loadData(){
			if (this.isEdit) {
				this.is_radius_enabled = this.custom_list.type?.key == "lat_long";
			}
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		async loadTypes() {
			this.resources.types = await this.$store.dispatch("custom_list/getTypes", {
				root: true,
			});
			return this.$store.dispatch("custom_list/getTypes", {
				root: true,
			});
		},

		/**
		 * From CardActions - line item
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleSubmit({ continue: false });
					break;

				case "save-continue":
					await this.handleSubmit({ continue: true });
					break;

				case "cancel":
					this.handleCancel();
					break;
			}
		},

		handleSubmit(params: { continue: Boolean } = { continue: true }) {
			const { id, name, custom_list_type_id, default_radius_km, active } =
				this.custom_list;

			this.$emit("save", {
				method: this.isEdit ? "update" : "create",
				continue: params.continue,
				data: {
					id,
					name,
					custom_list_type_id,
					default_radius_km,
					active,
				},
			});
		},
		async handleListSubmit({ edited, deleted, created, done }) {
			const dataPrepared = {
				id: this.custom_list.id,
				name: this.custom_list.name,
				active: this.custom_list.active,
				custom_list_type_id: this.custom_list.custom_list_type_id,
				default_radius_km: Number(this.custom_list.default_radius_km),
				edited: edited,
				deleted: deleted,
				created: created,
				done: done
			}
			await this.$emit("save", dataPrepared);
		},

		handleCancel() {
			this.$emit("cancel");
		},

		CheckTypes(id: any){
			if(isUndefined(id)) return false;
			const type = this.resources.types.find(
				(item: { id: any }) => item.id == id
			);
			return type?.key == "lat_long";
		}
	},
	watch: {
		"custom_list.custom_list_type_id"(val) {
			this.custom_list.custom_list_type_id = val;
			this.CheckTypes(val);
		},
	},
});
